<template>
  <div>
    <span v-for="(part, i) in parts" :key="i" :class="getColour(part)">{{ part.value }}</span>
  </div>
</template>

<script>
const Diff = require("diff");

export default {
  props: {
    oldValue: { type: String, required: true, default: "" },
    newValue: { type: String, required: true, default: "" }
  },
  data() {
    return {
      parts: []
    };
  },
  methods: {
    getColour(part) {
      return part.added ? "added" : part.removed ? "removed" : "unchanged";
    }
  },
  mounted() {
    this.parts = Diff.diffChars(this.oldValue, this.newValue);
    // console.log(this.parts);
  }
};
</script>

<style scoped>
/* .unchanged {
  color: grey;
} */
.added {
  background-color: rgba(223, 246, 221, 1);
}
.removed {
  text-decoration: line-through;
  color: red;
}
</style>
