<template>
  <section class="section is-main-section">
    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Care Plans"
          icon="notebook-multiple"
          :header-icons="headerIcons"
          :tableData="tableData"
          :columns="columns"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="editCarePlan"
          :isLoading="isLoading"
        >
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: { ContextTableCard },
  mixins: [ContextTableMixin],
  data() {
    return {
      url: "careplans",
      entity: "Care Plans",
      headerIcons: [IconsMixin.headerIconAdd("Care Plan"), IconsMixin.headerIconRefresh()],
      columns: [{ field: "name", label: "Name", sortable: true }],
      search: {
        locationId: null,
        name: null,
        page: 1,
        sortField: "lastname",
        sortOrder: "asc"
      }
    };
  },
  methods: {
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$router.push({ name: "careplan.new" });
          break;
      }
    },
    editCarePlan() {
      if (this.selected.carePlanId) this.$router.push({ name: "careplan.edit", params: { id: this.selected.carePlanId } });
    }
  }
};
</script>
