<template>
  <div>
    <CarePlanEdit :id="id" ref="careplanEdit" />
    <StringDiff
      oldValue="Jimmy needs to be turnned in his bed every 4 hours"
      newValue="Jimmy should to be turned in his bed every 3 hours"
    />
    <br />
  </div>
</template>

<script>
import CarePlanEdit from "@/components/careplans/CarePlanEdit";
import CheckDirtySubComponent from "@/mixins/checkDirtySubComponent";
import StringDiff from "@/components/StringDiff.vue";

export default {
  mixins: [CheckDirtySubComponent],
  components: {
    CarePlanEdit,
    StringDiff
  },
  props: {
    id: { type: [String, Number], default: null }
  },
  // CarePlanEdit is not route level component, so we need to pass a ref to the route leave event
  beforeRouteLeave: function(to, from, next) {
    this.checkSubComponentIsiDrty(to, from, next, "careplanEdit");
  }
};
</script>
